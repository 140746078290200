// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #fcffff;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.legend-color {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border: 1px solid #999;
}

.legend-label {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/containers/Dashboard/History/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,iBAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".legend {\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n  padding: 10px;\r\n  border-radius: 5px;\r\n  background-color: #fcffff;\r\n}\r\n\r\n.legend-item {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 1px;\r\n}\r\n\r\n.legend-color {\r\n  width: 8px;\r\n  height: 8px;\r\n  margin-right: 5px;\r\n  border: 1px solid #999;\r\n}\r\n\r\n.legend-label {\r\n  font-size: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
