// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  width: 100%;
  height: 100%;
}

.dashboard-content-container {
  display: flex;
  height: calc(100% - 3.5rem);
}

.dashboard-content {
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
}

.dashboard-content .ant-menu {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/containers/Dashboard/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".dashboard-container{\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.dashboard-content-container{\r\n  display: flex;\r\n  height: calc(100% - 3.5rem);\r\n}\r\n\r\n.dashboard-content{\r\n  width: 100%;\r\n  padding: 1rem;\r\n  overflow-y: auto;\r\n}\r\n\r\n.dashboard-content .ant-menu {\r\n  height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
